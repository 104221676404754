import React from "react";
import styled from "styled-components";
import LounaspoytaImage from "../assets/images/lounaspoyta.jpg";
import SalaattipoytaImage from "../assets/images/salaattipoyta.jpg";
import TiskiImage from "../assets/images/tiski.JPG";

const HeaderContainer = styled.header`
  text-align: center;
  padding: 40px 0;
`;

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allows images to wrap on smaller screens */
  gap: 20px; /* Space between images */
`;

const ImageWrapper = styled.div`
  width: 400px; /* Set desired width */
  height: 300px; /* Set desired height */
  overflow: hidden;
  border-radius: 10px;
`;

const RestaurantImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
`;

const Header: React.FC = () => {
  return (
    <HeaderContainer>
      <ImagesContainer>
        <ImageWrapper>
          <RestaurantImage
            src={LounaspoytaImage}
            alt="Lounaspöytä täynnä herkullisia ruokia"
          />
        </ImageWrapper>
        <ImageWrapper>
          <RestaurantImage
            src={SalaattipoytaImage}
            alt="Erilaisia herkullisia salaatteja"
          />
        </ImageWrapper>
        <ImageWrapper>
          <RestaurantImage src={TiskiImage} alt="Ravintolan tiski" />
        </ImageWrapper>
      </ImagesContainer>
    </HeaderContainer>
  );
};

export default Header;
