import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStyles from "./styles/GlobalStyles";
import Header from "./components/Header";
import Introduction from "./components/Introduction";
import ContactDetails from "./components/ContactDetails";
import Footer from "./components/Footer";
import MapSection from "./components/MapSection";
import NotFoundPage from "./components/NotFoundPage";

const HomePage: React.FC = () => {
  return (
    <>
      <Header />
      <Introduction />
      <ContactDetails />
      <Footer />
      <MapSection />
    </>
  );
};

const App: React.FC = () => {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
