import React from "react";
import styled from "styled-components";

const IntroContainer = styled.section`
  text-align: center;
  padding: 20px;
`;

const IntroHeader = styled.h2`
  margin-bottom: 20px;
`;

const IntroText = styled.p`
  margin: 10px 0;
  line-height: 1.6;
`;

const Introduction: React.FC = () => {
  return (
    <IntroContainer>
      <IntroHeader>Tervetuloa Lounaskahvio Saanaan!</IntroHeader>
      <IntroText>
        Tarjoamme arkisin herkullisen lounaan klo 10–14. Valikoimastamme löydät
        reilut grilliannokset, pizzat ja muut herkut, jotka tyydyttävät
        isommankin nälän.
      </IntroText>
      <IntroText>Rahtareille myönnämme 10 % alennuksen!</IntroText>
    </IntroContainer>
  );
};

export default Introduction;
