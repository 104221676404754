import React from "react";
import styled from "styled-components";

const MapContainer = styled.section`
  text-align: center;
  padding: 40px 0;
`;

const MapSection: React.FC = () => {
  return (
    <MapContainer>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1816.5793448178283!2d30.24892127796035!3d62.915518618986376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469c66403e4880e1%3A0x97b365fc94d55174!2sLounaskahvio%20Saana!5e0!3m2!1sfi!2sfi!4v1726828719492!5m2!1sfi!2sfi"
        width="500"
        height="500"
        style={{ border: 0 }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </MapContainer>
  );
};

export default MapSection;
