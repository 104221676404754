import React from "react";
import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";

const FooterContainer = styled.footer`
  text-align: center;
  padding: 20px;
`;

const IconLink = styled.a`
  color: #ffffff;
  font-size: 2rem;
  margin: 0 10px;
  transition: color 0.3s;

  &:hover {
    color: #4267b2; // Facebook blue
  }
`;

const InfoText = styled.p`
  margin: 5px 0;
  line-height: 1.6;
  font-weight: bold;
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <InfoText>
        Ajankohtaiset tiedot ja lounaslistan löydät Facebook-sivuiltamme:
      </InfoText>
      <IconLink
        href="https://www.facebook.com/profile.php?id=100057277726633"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebook />
      </IconLink>
    </FooterContainer>
  );
};

export default Footer;
