// src/components/NotFoundPage.tsx
import React from "react";
import styled from "styled-components";

const NotFoundContainer = styled.div`
  text-align: center;
  padding: 40px;
`;

const NotFoundPage: React.FC = () => (
  <NotFoundContainer>
    <h1>404 - Sivua ei löytynyt</h1>
    <p>Valitettavasti etsimääsi sivua ei löytynyt.</p>
    <a href="/">Palaa etusivulle</a>
  </NotFoundContainer>
);

export default NotFoundPage;
